import React from 'react';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

const ClientsSection = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções
  const settings = {
    dots: false,
    infinite: true,
    speed: 10000,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <SectionContainer>
      <h2>{t('clients_h2')}</h2>

      <SliderContainer>
        <Slider {...settings}>
          <ClientLogo src={require('../assets/logo-my-site-5.png')} alt="Service 2" />
          <ClientLogo src={require('../assets/gf_solucoes_logo.png')} alt="Service 2" />
          <ClientLogo src={require('../assets/unifor_1.png')} alt="Service 2" />
          <ClientLogo src={require('../assets/medimax.png')} alt="Service 2" />
          <ClientLogo src={require('../assets/skyline.png')} alt="Service 2" />
          <ClientLogo src={require('../assets/codecraft.png')} alt="Service 2" />
        </Slider>
      </SliderContainer>
    </SectionContainer>
  );
};

// Estilização com styled-components

const SectionContainer = styled.section`
  padding: 40px 20px;
  text-align: center;
  background-color: #f5f5f5;

  h2 {
    margin-bottom: 30px;
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.5rem; /* Reduzindo o tamanho da fonte em telas menores */
    }
  }
`;

const SliderContainer = styled.div`
  .slick-prev:before, .slick-next:before {
    color: #333;
  }
`;

const ClientLogo = styled.img`
  width: auto;
  height: 60px;
  object-fit: contain;
  opacity: 0.8;
  margin: 0 10px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  @media (max-width: 768px) {
    height: 50px; /* Ajustando a altura das logos em dispositivos menores */
  }

  @media (max-width: 480px) {
    height: 40px; /* Reduzindo ainda mais em dispositivos pequenos */
  }
`;

export default ClientsSection;

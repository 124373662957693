import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion'; // Importando framer-motion
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções
 
  return (
    <AboutContainer>
      <HeroSection
        as={motion.section}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <h1>{ t('about_h1') }</h1>
        <p>{ t('about_p') }</p>
      </HeroSection>

      <Section
        as={motion.section}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h2>{ t('about_h2') }</h2>
        <p>{ t('about_p2') }
          
        </p>
      </Section>

      <Section
        as={motion.section}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <h2>{ t('about_h2_2') }</h2>
        <p>{ t('about_p3') }
        </p>
      </Section>

      <Section
        as={motion.section}
        initial={{ opacity: 0, y: 100 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <h2>{ t('about_h2_3') }</h2>
        <ValuesContainer>
          <ValueBox
            as={motion.div}
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <h3>{ t('about_h3') }</h3>
            <p>{ t('about_p4') }</p>
          </ValueBox>
          <ValueBox
            as={motion.div}
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3>{ t('about_h3_2') }</h3>
            <p>{ t('about_p5') }</p>
          </ValueBox>
          <ValueBox
            as={motion.div}
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3>{ t('about_h3_3') }</h3>
            <p>{ t('about_p6') }</p>
          </ValueBox>
        </ValuesContainer>
      </Section>

      <Section
        as={motion.section}
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h2>{ t('about_h2_4') }</h2>
        <p>{ t('about_p7') }
        
        </p>
      </Section>
    </AboutContainer>
  );
};

// Estilos com styled-components

const AboutContainer = styled.div`
  padding: 60px 20px;
  background-color:${({ theme }) => theme.colors.background_second}; 
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 80px;
  background: linear-gradient(135deg, ${({ theme }) => theme.colors.background}, ${({ theme }) => theme.colors.background_second});
  color: ${({ theme }) => theme.colors.white}; 

  h1 {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white}; 
  }

  p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
    max-width: 600px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 60px 20px;
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const Section = styled.section`
  padding: 40px 0;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 30px 20px;
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
  padding: 0 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ValueBox = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.background_light};
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  width: 280px;
  text-align: center;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.text};
  }

  @media (max-width: 768px) {
    width: 90%;
    margin-bottom: 20px;
  }
`;

export default About;

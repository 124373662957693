import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaGlobe } from 'react-icons/fa'; // Ícone de globo para o dropdown

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };

  return (
    <Dropdown>
      <DropdownToggle onClick={() => setDropdownOpen(!dropdownOpen)}>
        <FaGlobe style={{ marginRight: '5px' }} />
        {i18n.language.toUpperCase()}
      </DropdownToggle>
      {dropdownOpen && (
        <DropdownMenu>
          <DropdownItem onClick={() => changeLanguage('en')}>
            <FlagIcon src="https://cdn-icons-png.flaticon.com/512/197/197374.png" alt="English" /> English
          </DropdownItem>
          <DropdownItem onClick={() => changeLanguage('pt')}>
            <FlagIcon src="https://cdn-icons-png.flaticon.com/512/197/197386.png" alt="Portuguese" /> Português
          </DropdownItem>
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

// Styled Components

const Dropdown = styled.div`
  position: relative;
`;

const DropdownToggle = styled.button`
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 20px;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(0, 0, 0, 0.7);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 120%;
  right: 0;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.accent};
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: fadeIn 0.3s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const DropdownItem = styled.button`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accent};
    color: white;
  }
`;

const FlagIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
`;

export default LanguageSwitcher;

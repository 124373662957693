import React from 'react';
import styled from 'styled-components';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções
  const scrollToSection = () => {
    const section = document.getElementById('about-section');
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <HeroSection>
        <LeftSide>
          <h1>Nexus</h1>
          <p>{t('hero_p')}</p>
          <CTAButton onClick={scrollToSection}>{t('button_descubra_mais')}</CTAButton>
        </LeftSide>
        <RightSide>
          <video src={require('../assets/video_1.mp4')} autoPlay muted loop />
        </RightSide>
      </HeroSection>
    </>
  );
};

// Estilização com styled-components

const HeroSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px;
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 50px 20px; /* Reduz o padding em telas menores */
  }
`;

const LeftSide = styled.div`
  width: 50%;
  max-width: 100%; 
  
  h1 {
    font-size: 3rem;
    color: #333;
    font-family: ${({ theme }) => theme.fonts.secondary};
    
    @media (max-width: 768px) {
      font-size: 2rem; /* Ajusta o tamanho da fonte em telas menores */
      text-align: center;
    }
  }

  p {
    font-size: 1.5rem;
    margin: 20px 0;

    @media (max-width: 768px) {
      font-size: 1.2rem; /* Reduz o tamanho do texto em telas menores */
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    width: 100%; /* Faz com que o conteúdo ocupe toda a largura da tela */
  }
`;

const RightSide = styled.div`
  width: 50%;
  video {
    margin-left: 25%;
    height: auto;
    border-radius: 8px;
    width: 70%;
    max-width: 600px;

    @media (max-width: 768px) {
      width: 100%; /* O vídeo ocupa toda a largura em telas menores */
      margin-left: 0;
      max-width: 100%; /* Remove o limite máximo de largura em telas menores */
    }
  }

  @media (max-width: 768px) {
    width: 100%; /* Faz com que o vídeo ocupe toda a largura em telas menores */
    margin-top: 20px; /* Adiciona um espaçamento entre o vídeo e o texto em telas menores */
  }
`;

const CTAButton = styled.button`
  padding: 20px 40px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 768px) {
    padding: 15px 30px; /* Reduz o padding do botão em telas menores */
    font-size: 1rem; /* Ajusta o tamanho da fonte do botão */
  }
`;

export default Hero;

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Banner = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções
  return (
    <BannerContainer>
      <p>{t('banner')}</p>
    </BannerContainer>
  );
};

// Estilos para a faixa com responsividade
const BannerContainer = styled.section`
  background-color: #f1f1f1;
  padding: 60px 60px;
  text-align: center;
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.main};
  color: #333;
  font-weight: bold;

  p {
    margin: 0;
    font-weight: bold;
  }

  // Responsividade
  @media (max-width: 1024px) {
    padding: 40px 40px; // Reduz o padding em tablets
    font-size: 1.3rem;  // Reduz o tamanho da fonte em tablets
  }

  @media (max-width: 768px) {
    padding: 30px 20px; // Reduz o padding em dispositivos menores
    font-size: 1.2rem;  // Reduz o tamanho da fonte em dispositivos menores
  }

  @media (max-width: 480px) {
    padding: 20px 10px; // Reduz o padding em smartphones
    font-size: 1rem;    // Reduz o tamanho da fonte em smartphones
    line-height: 1.4;   // Ajusta o espaçamento entre linhas em telas menores
  }
`;

export default Banner;

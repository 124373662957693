import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: ${({ theme }) => theme.fonts.main}; // Aplicando a fonte principal
    background-color: ${({ theme }) => theme.colors.background}; // Cor de fundo
    color: ${({ theme }) => theme.colors.text}; // Cor do texto
    line-height: 1.6;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.fonts.main}; // Aplicando a fonte principal nos títulos
    color: ${({ theme }) => theme.colors.primary}; // Cor principal nos títulos
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.secondary}; // Cor secundária para links
    transition: color 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.colors.accent}; // Cor de destaque no hover
    }
  }
`;

export default GlobalStyle;

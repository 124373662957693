import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from '../src/components/Header';
import Footer from '../src/components/Footer';
import Home from '../src/pages/Home';
import About from '../src/pages/About';
import Services from '../src/pages/Services';
import Portfolio from '../src/pages/Portfolio';
import Contact from '../src/pages/Contact';
import { MyContextProvider } from '../src/context/context'; 
import { ThemeProvider } from 'styled-components'; // Importando o ThemeProvider
import GlobalStyle from './styles/GlobalStyle'; // GlobalStyle que inclui a tipografia
import theme from './styles/theme'; // Importando o tema de cores e fontes
import WebDevelopment from '../src/pages/WebDevelopment';
import MobileDevelopment from '../src/pages/MobileDevelopment';
import OrcamentoSimulacao from '../src/pages/OrcamentoSimulacao';
import UIUX from '../src/pages/UIUX';
import Consultoria from '../src/pages/Consultoria';
import WhatsAppButton from './components/WhatsAppButton'; // Verifique o caminho correto do arquivo
import './i18n.js'; // Importa a configuração do i18next

const App = () => {

  return (
    <MyContextProvider>
      <ThemeProvider theme={theme}> {/* Envolvendo com ThemeProvider */}
        <GlobalStyle /> {/* Aplicando estilos globais */}
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/orcamento" element={<OrcamentoSimulacao />} />
            <Route path="/services/web-development" element={<WebDevelopment />} />
            <Route path="/services/mobile-development" element={<MobileDevelopment />} />
            <Route path="/services/ui-ux" element={<UIUX />} />
            <Route path="/services/consultoria" element={<Consultoria />} />
          </Routes>
          <Footer />
        </Router>
        <WhatsAppButton />
      </ThemeProvider>
    </MyContextProvider>
  );
};

export default App;

// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          home: 'Home',
          about: 'About Us',
          services: 'Services',
          contact: 'Contact',
          hero_p: 'Innovation, technology and results: everything you need is here.',
          button_descubra_mais: 'Discover More',
          banner: 'We develop innovative technological solutions that boost companies digital success, connecting ideas to real results.',
          home_h2: 'Discover Our Solutions',
          home_mob_p: 'We develop modern, efficient mobile applications designed for the user, with cutting-edge technology and exceptional usability.',
          home_web_p: 'Elegant and functional web solutions, customized to highlight your business in the digital environment with performance and security.',
          cta_h2: 'Create something amazing with Nexus',
          cta_p: 'Want to develop the next revolutionary app or website that highlights your business? We are ready to transform your ideas into digital reality.',
          cta_button: 'Get in touch',
          clients_h2: 'Companies that trust Nexus',
          infoSection_h2: 'Created by Nexus',
          infoSection_p_1: 'Transforming ideas into innovative digital solutions.',
          infoSection_p_2: 'We use the most advanced technologies to create web and mobile solutions.',
          infoSection_p_3: 'Chosen by innovative companies seeking personalized development.',
          infoSection_p_4: 'Polished designs to guarantee performance and robustness.',
          infoSection_h3_1: 'INNOVATION',
          infoSection_h3_2: 'REFERENCE',
          infoSection_h3_3: 'QUALITY',
          footer_h3: 'Navigation',
          email: 'E-mail',
          telefone: 'Telephone',
          endereço: 'Address',
          siganos: 'Follow us',
          newsletter: 'Subscribe to the Newsletter',
          newsletter_p: 'Receive the latest news and updates directly to your email.',
          inscreverse: 'Subscribe',
          mensagemSucesso: 'Registration successful!',
          mensagemError: 'An error has occurred. Try again.',
          footerBottom: '© 2024 Nexus. All rights reserved.',
          about_h1: 'About Nexus',
          about_p: 'Innovation in every touch. We transform ideas into digital reality.',
          about_h2: 'Our Mission',
          about_p2: 'At Nexus, our mission is to create impactful digital solutions that help companies grow and stand out in the market.',
          about_h2_2: 'Our Vision',
          about_p3: 'To be recognized as a reference in technological development, creating innovative and impactful products.',
          about_h2_3: 'Our Values',
          about_h3: 'Innovation',
          about_p4: 'We always look for new things to offer unique solutions.',
          about_h3_2: 'Excellence',
          about_p5: 'We are dedicated to delivering the highest quality products.',
          about_h3_3: 'Partnership',
          about_p6: 'We work side by side with our customers.',
          about_p7: 'Nexus was founded with the purpose of offering innovative technological solutions that could transform the way companies and people connect and operate in the digital world. The idea arose from the observation of a growing need for high-quality services in web development, mobile and process automation, especially for companies that sought not only to keep up with trends, but to stand out in the market. In the beginning, Nexus was just an idea in the mind of its founder, driven by a passion for technology and a desire to solve complex problems. With more than 5 years of experience in the market, the founder noticed that many companies faced challenges in finding technology partners who truly understood their needs and who were committed to delivering personalized and effective solutions.',
          about_h2_4: 'Our History',
          contact_h2: 'Contact Us',
          name_label: 'Name',
          email_label: 'Email',
          message_label: 'Message',
          send_message_button: 'Send Message',
          success_message: 'Thank you for getting in touch! Your message has been sent successfully. I will respond as soon as possible.',
          error_message: 'An error occurred while sending your message. Please try again later.',
          contact_direct: 'Direct Contact',
          phone: 'Phone',
          visit_us: 'Visit Us',
          projects_h1: 'Our Projects',
          project_ecommerce_title: 'E-commerce Project',
          project_ecommerce_description: 'An e-commerce platform with modern functionalities.',
          project_ecommerce_details: 'This project includes a fully functional e-commerce platform with payment integration, shopping cart, and user management.',
          project_delivery_title: 'Delivery App',
          project_delivery_description: 'Mobile app for food and product delivery.',
          project_delivery_details: 'The delivery app offers an intuitive experience for users to order food and other products with real-time tracking and support for multiple payment methods.',
          project_solar_title: 'Solar Energy Web Project',
          project_solar_description: 'Automation of processes for a manufacturing company.',
          project_solar_details: 'Our team helped the company automate manual processes, resulting in a significant increase in efficiency and a reduction in errors.',
          project_medicine_title: 'Medication Management App',
          project_medicine_description: 'Development of a mobile app to assist in medication administration.',
          project_medicine_details: 'Our team developed an intuitive app to manage medication administration, with automatic alerts and reports on medication schedules, meals, and treatments. The system helped increase user adherence to treatment, ensuring greater safety and precision in the process.',
          project_finance_title: 'Finance App',
          project_finance_description: 'Mobile app for personal finance management.',
          project_finance_details: 'We created an app that helps users manage their personal finances, with features such as budget planning, expense categorization, and report generation. The project allowed users to have a better view of their finances and more control over their spending.',
          services_h1: 'Our Services',
          services_description: 'See how we can help transform your ideas into digital reality.',
          web_development_alt: 'Web Development',
          web_development_title: 'Web Development',
          web_development_description: 'We create modern and responsive websites to showcase your business in the digital world.',
          mobile_development_alt: 'Mobile Development',
          mobile_development_title: 'Mobile Development',
          mobile_development_description: 'Innovative and functional Android and iOS apps for your company.',
          ui_ux_design_alt: 'UI/UX Design',
          ui_ux_design_title: 'UI/UX Design',
          ui_ux_design_description: 'User experiences and interfaces that delight and facilitate navigation.',
          consulting_alt: 'Tech Consulting',
          consulting_title: 'Tech Consulting',
          consulting_description: 'Tailored technological solutions to boost your business efficiency.',
          learn_more_button: 'Learn More',
          webdev_h1: 'Web Development',
          webdev_hero_p: 'We create modern and responsive websites that elevate your online presence.',
          webdev_offer_h2: 'What We Offer',
          webdev_offer_p: 'At Nexus, we develop custom websites to meet your business needs. Our team uses the latest technologies to create fast, secure, and fully responsive sites, ensuring a flawless user experience on all devices.',
          webdev_feature1_h3: 'Responsive Design',
          webdev_feature1_p: 'Your websites will look stunning on any device, be it desktop, tablet, or smartphone.',
          webdev_feature2_h3: 'SEO Optimized',
          webdev_feature2_p: 'We ensure that your site has good visibility in search engines like Google.',
          webdev_feature3_h3: 'System Integration',
          webdev_feature3_p: 'We connect your site to payment systems, CRMs, and more for an integrated experience.',
          webdev_projects_h2: 'Recent Projects',
          project1_alt: 'Web Project 1',
          project1_title: 'Company X Website',
          project1_description: 'We developed Company X\'s website, creating an intuitive and modern platform.',
          project2_alt: 'Web Project 2',
          project2_title: 'Online Store Y',
          project2_description: 'We helped Store Y expand its online sales with an optimized e-commerce solution.',
          project3_alt: 'Web Project 3',
          project3_title: 'Online Store Y',
          project3_description: 'We helped Store Y expand its online sales with an optimized e-commerce solution.',
          testimonials_h2: 'Testimonials',
          testimonial1_p: '"Nexus transformed our website into a true sales tool. We are extremely satisfied with the results!"',
          testimonial1_author: '— Satisfied Client',
          cta_h2: 'Interested in a High-Quality Website?',
          cta_button: 'Get in Touch',
          mobiledev_h1: 'Mobile Development',
          mobiledev_hero_p: 'We create Android and iOS apps that provide incredible experiences.',
          mobiledev_offer_h2: 'What We Offer',
          mobiledev_offer_p: 'At Nexus, we develop custom, functional, and highly scalable mobile apps. We create native and hybrid apps for Android and iOS platforms, with a focus on performance, design, and usability.',
          mobiledev_feature1_h3: 'Optimized Performance',
          mobiledev_feature1_p: 'Our apps are fast and lightweight, providing a smooth user experience.',
          mobiledev_feature2_h3: 'Compatibility Across Devices',
          mobiledev_feature2_p: 'Apps that work perfectly across a wide range of devices and operating system versions.',
          mobiledev_feature3_h3: 'API and Backend Integration',
          mobiledev_feature3_p: 'We integrate your app with APIs and backend systems to ensure full functionality.',
          mobiledev_projects_h2: 'Recent Projects',
          project1_alt: 'App 1',
          project1_title: 'Fitness App',
          project1_description: 'We developed a fitness app to monitor physical activities and nutrition, with wearable integration.',
          project2_alt: 'App 2',
          project2_title: 'Delivery Platform',
          project2_description: 'We created an intuitive and fast delivery app with payment integration and real-time order tracking.',
          project3_alt: 'App 3',
          project3_title: 'Educational App',
          project3_description: 'An interactive educational platform with online courses, quizzes, and digital certifications for students and professionals.',
          project4_alt: 'App 4',
          project4_title: 'Finance App',
          project4_description: 'A personal finance app with expense analysis, budget creation, and personalized savings alerts.',
          testimonials_h2: 'Testimonials',
          testimonial1_p: '"The app developed by Nexus significantly improved our users\' experience. Great performance!"',
          testimonial1_author: '— Satisfied Client',
          cta_h2: 'Need an App for Your Business?',
          cta_button: 'Get in Touch',
          uiux_h1: 'UI/UX Design',
          uiux_hero_p: 'We create intuitive interfaces and experiences that delight users.',
          uiux_offer_h2: 'What We Offer',
          uiux_offer_p: 'At Nexus, we believe that design goes beyond aesthetics. We create intuitive and functional interfaces that provide exceptional user experiences. Our user-centered approach ensures that every interaction is fluid and efficient.',
          uiux_feature1_h3: 'User-Centered Design',
          uiux_feature1_p: 'We use design thinking practices to ensure that the focus is always on the needs of the end user.',
          uiux_feature2_h3: 'Rapid Prototyping',
          uiux_feature2_p: 'We quickly test ideas with interactive prototypes to validate concepts before development.',
          uiux_feature3_h3: 'Responsive Design',
          uiux_feature3_p: 'We create interfaces that adapt to any device, providing a consistent experience.',
          uiux_projects_h2: 'Recent Projects',
          project1_alt: 'UI/UX Project 1',
          project1_title: 'E-commerce App',
          project1_description: 'We designed an e-commerce app focused on user experience, increasing conversion rates.',
          project2_alt: 'UI/UX Project 2',
          project2_title: 'Administrative Dashboard',
          project2_description: 'Creation of an intuitive dashboard to facilitate real-time data and task management.',
          testimonials_h2: 'Testimonials',
          testimonial1_p: '"Nexus\' UI/UX design took our product to the next level. Our users loved the new experience!"',
          testimonial1_author: '— Satisfied Client',
          cta_h2: 'Want to improve your user experience?',
          cta_button: 'Get in Touch',
          consultoria_h1: 'Tech Consulting',
          consultoria_hero_p: 'We help your company find the best technological solutions to grow efficiently.',
          consultoria_offer_h2: 'What We Offer',
          consultoria_offer_p: 'At Nexus, we offer tech consulting to help your company find and implement efficient solutions. Our team works to understand your needs and create a strategic plan with the best market practices.',
          consultoria_feature1_h3: 'Needs Diagnosis',
          consultoria_feature1_p: 'We analyze your business\'s current scenario and identify opportunities for optimization with technology.',
          consultoria_feature2_h3: 'Customized Strategies',
          consultoria_feature2_p: 'We create an action plan with tailored tech solutions for your specific challenges.',
          consultoria_feature3_h3: 'Implementation and Support',
          consultoria_feature3_p: 'We provide full support during implementation and training for your team.',
          consultoria_projects_h2: 'Recent Projects',
          project1_alt: 'Consulting Project 1',
          project1_title: 'Process Automation',
          project1_description: 'We helped a company automate its internal processes, reducing time spent on manual tasks.',
          project2_alt: 'Consulting Project 2',
          project2_title: 'System Integration',
          project2_description: 'We developed a customized solution to integrate different systems into a single platform, facilitating data management.',
          testimonials_h2: 'Testimonials',
          testimonial1_p: '"Nexus\' consulting was essential for modernizing our tech infrastructure. They truly understood our needs and delivered effective solutions."',
          testimonial1_author: '— Satisfied Client',
          cta_h2: 'Need Tech Consulting?',
          cta_button: 'Get in Touch',
        },
      },
      pt: {
        translation: {
          home: 'Home',
          about: 'Sobre Nós',
          services: 'Serviços',
          contact: 'Contato',
          hero_p: 'Inovação, tecnologia e resultados: tudo o que você precisa está aqui.',
          button_descubra_mais: 'Descubra Mais',
          banner: 'Desenvolvemos soluções tecnológicas inovadoras que impulsionam o sucesso digital das empresas, conectando ideias a resultados reais.',
          home_h2: 'Conheça Nossas Soluções',
          home_mob_p: 'Desenvolvemos aplicativos móveis modernos, eficientes e pensados para o usuário, com tecnologia de ponta e usabilidade excepcional.',
          home_web_p: 'Soluções web elegantes e funcionais, personalizadas para destacar o seu negócio no ambiente digital com desempenho e segurança.',
          cta_h2: 'Crie algo incrível com a Nexus',
          cta_p: 'Quer desenvolver o próximo app revolucionário ou um site que destaque seu negócio? Nós estamos prontos para transformar suas ideias em realidade digital.',
          cta_button: 'Entre em Contato',
          clients_h2: 'Empresas que confiaram na Nexus',
          infoSection_h2: 'Criado por Nexus',
          infoSection_p_1: 'Transformando ideias em soluções digitais inovadoras.',
          infoSection_p_2: 'Utilizamos as tecnologias mais avançadas para criar soluções web e mobile.',
          infoSection_p_3: 'Escolhidos por empresas inovadoras que buscam desenvolvimento personalizado.',
          infoSection_p_4: 'Projetos lapidados para garantir desempenho e robustez.',
          infoSection_h3_1: 'INOVAÇÃO',
          infoSection_h3_2: 'REFERÊNCIA',
          infoSection_h3_3: 'QUALIDADE',
          footer_h3: 'Navegação',
          email: 'Email',
          telefone: 'Telefone',
          endereço: 'Endereço',
          siganos: 'Siga-nos',
          newsletter: 'Inscreva-se no Newsletter',
          newsletter_p: 'Receba as últimas novidades e atualizações diretamente no seu email.',
          inscreverse: 'Inscrever-se',
          mensagemSucesso: 'Inscrição realizada com sucesso!',
          mensagemError: 'Ocorreu um erro. Tente novamente.',
          footerBottom: '© 2024 Nexus. Todos os direitos reservados.',
          about_h1: 'Sobre a Nexus',
          about_p: 'Inovação em cada toque. Transformamos ideias em realidade digital.',
          about_h2: 'Nossa Missão',
          about_p2: 'Na Nexus, nossa missão é criar soluções digitais impactantes, que ajudam empresas a crescer e a se destacar no mercado.',
          about_h2_2: 'Nossa Visão',
          about_p3: 'Ser reconhecida como referência em desenvolvimento tecnológico, criando produtos inovadores e impactantes.',
          about_h2_3: 'Nossos Valores',
          about_h3: 'Inovação',
          about_p4: 'Buscamos sempre o novo para oferecer soluções únicas.',
          about_h3_2: 'Excelência',
          about_p5: 'Nos dedicamos a entregar produtos da mais alta qualidade.',
          about_h3_3: 'Parceria',
          about_p6: 'Trabalhamos lado a lado com nossos clientes.',
          about_p7: 'A Nexus foi fundada com o propósito de oferecer soluções tecnológicas inovadoras que pudessem transformar a maneira como empresas e pessoas se conectam e operam no mundo digital. A ideia surgiu da observação de uma necessidade crescente por serviços de alta qualidade em desenvolvimento web, mobile e automação de processos, especialmente para empresas que buscavam não apenas acompanhar as tendências, mas se destacar no mercado. No início, a Nexus era apenas uma ideia na mente da sua fundadora, impulsionada pela paixão por tecnologia e pela vontade de resolver problemas complexos. Com mais de 5 anos de experiência no mercado, a fundadora notou que muitas empresas enfrentavam desafios em encontrar parceiros tecnológicos que realmente entendessem suas necessidades e que estivessem comprometidos em entregar soluções personalizadas e eficazes.',
          about_h2_4: 'Nossa História',
          contact_h2: 'Entre em Contato',
          name_label: 'Nome',
          email_label: 'Email',
          message_label: 'Mensagem',
          send_message_button: 'Enviar Mensagem',
          success_message: 'Obrigado por entrar em contato! Sua mensagem foi enviada com sucesso. Vou responder o mais rápido possível.',
          error_message: ' Ocorreu um erro ao enviar sua mensagem. Por favor, tente novamente mais tarde.',
          contact_direct: 'Entre em Contato Direto',
          phone: 'Telefone',
          visit_us: 'Visite-nos',
          projects_h1: 'Nossos Projetos',
          project_ecommerce_title: 'Projeto E-commerce',
          project_ecommerce_description: 'Uma plataforma de e-commerce com funcionalidades modernas.',
          project_ecommerce_details: 'Este projeto inclui uma plataforma de e-commerce totalmente funcional com integração de pagamentos, carrinho de compras, e gerenciamento de usuários.',
          project_delivery_title: 'Aplicativo de Delivery',
          project_delivery_description: 'Aplicativo mobile para delivery de alimentos e produtos.',
          project_delivery_details: 'O aplicativo de delivery oferece uma experiência intuitiva para os usuários pedirem comida e outros produtos com rastreamento em tempo real e suporte a múltiplos meios de pagamento.',
          project_solar_title: 'Projeto Web Energia Fotovoltaica',
          project_solar_description: 'Automação de processos para uma empresa de manufatura.',
          project_solar_details: 'Nossa equipe ajudou a empresa a automatizar processos manuais, resultando em um aumento significativo na eficiência e redução de erros.',
          project_medicine_title: 'Aplicativo para Gerenciamento de Medicamentos',
          project_medicine_description: 'Desenvolvimento de um aplicativo mobile para auxiliar na administração de medicamentos.',
          project_medicine_details: 'Nossa equipe desenvolveu um aplicativo intuitivo para gerenciar a administração de medicamentos, com alertas automáticos e relatórios sobre os horários de medicação, refeições e tratamentos. O sistema ajudou a aumentar a adesão dos usuários ao tratamento, garantindo maior segurança e precisão no processo.',
          project_finance_title: 'Aplicativo de Finanças',
          project_finance_description: 'Aplicativo mobile para gestão de finanças pessoais.',
          project_finance_details: 'Criamos um aplicativo que auxilia os usuários a controlar suas finanças pessoais, com funcionalidades como planejamento de orçamento, categorização de despesas e geração de relatórios. O projeto permitiu aos usuários uma melhor visão de suas finanças e maior controle sobre seus gastos.',
          services_h1: 'Nossos Serviços',
          services_description: 'Veja como podemos ajudar a transformar suas ideias em realidade digital.',
          web_development_alt: 'Desenvolvimento Web',
          web_development_title: 'Desenvolvimento Web',
          web_development_description: 'Criamos websites modernos e responsivos para destacar seu negócio no mundo digital.',
          mobile_development_alt: 'Desenvolvimento Mobile',
          mobile_development_title: 'Desenvolvimento Mobile',
          mobile_development_description: 'Aplicativos para Android e iOS, inovadores e funcionais para sua empresa.',
          ui_ux_design_alt: 'Design UI/UX',
          ui_ux_design_title: 'Design UI/UX',
          ui_ux_design_description: 'Experiências de usuário e interfaces que encantam e facilitam a navegação.',
          consulting_alt: 'Consultoria Tecnológica',
          consulting_title: 'Consultoria Tecnológica',
          consulting_description: 'Soluções tecnológicas sob medida para aumentar a eficiência do seu negócio.',
          learn_more_button: 'Saiba Mais',
          webdev_h1: 'Desenvolvimento Web',
          webdev_hero_p: 'Criamos websites modernos e responsivos que elevam sua presença online.',
          webdev_offer_h2: 'O que Oferecemos',
          webdev_offer_p: 'Na Nexus, desenvolvemos websites personalizados para atender às necessidades do seu negócio. Nossa equipe utiliza as mais modernas tecnologias para criar sites rápidos, seguros e totalmente responsivos, garantindo uma experiência de usuário impecável em todos os dispositivos.',
          webdev_feature1_h3: 'Design Responsivo',
          webdev_feature1_p: 'Seus sites terão um visual incrível em qualquer dispositivo, seja desktop, tablet ou smartphone.',
          webdev_feature2_h3: 'SEO Otimizado',
          webdev_feature2_p: 'Garantimos que seu site tenha uma boa visibilidade em motores de busca como o Google.',
          webdev_feature3_h3: 'Integração com Sistemas',
          webdev_feature3_p: 'Conectamos seu site a sistemas de pagamento, CRMs e muito mais, para uma experiência integrada.',
          webdev_projects_h2: 'Projetos Recentes',
          project1_alt: 'Projeto Web 1',
          project1_title: 'Website Empresa X',
          project1_description: 'Desenvolvemos o site da Empresa X, criando uma plataforma intuitiva e moderna.',
          project2_alt: 'Projeto Web 2',
          project2_title: 'Loja Virtual Y',
          project2_description: 'Ajudamos a Loja Y a expandir suas vendas online com uma solução e-commerce otimizada.',
          project3_alt: 'Projeto Web 3',
          project3_title: 'Loja Virtual Y',
          project3_description: 'Ajudamos a Loja Y a expandir suas vendas online com uma solução e-commerce otimizada.',
          testimonials_h2: 'Depoimentos',
          testimonial1_p: '"A Nexus transformou nosso site em uma verdadeira ferramenta de vendas. Estamos extremamente satisfeitos com os resultados!"',
          testimonial1_author: '— Cliente Satisfeito',
          cta_h2: 'Interessado em um Website de Qualidade?',
          cta_button: 'Entre em Contato',
          mobiledev_h1: 'Desenvolvimento Mobile',
          mobiledev_hero_p: 'Criamos aplicativos para Android e iOS que oferecem experiências incríveis.',
          mobiledev_offer_h2: 'O que Oferecemos',
          mobiledev_offer_p: 'Na Nexus, desenvolvemos aplicativos móveis personalizados, funcionais e altamente escaláveis. Criamos apps nativos e híbridos para as plataformas Android e iOS, com foco em desempenho, design e usabilidade.',
          mobiledev_feature1_h3: 'Desempenho Otimizado',
          mobiledev_feature1_p: 'Nossos aplicativos são rápidos e leves, oferecendo uma experiência fluida ao usuário.',
          mobiledev_feature2_h3: 'Compatibilidade com Diversos Dispositivos',
          mobiledev_feature2_p: 'Aplicativos que funcionam perfeitamente em uma ampla gama de dispositivos e versões de sistema operacional.',
          mobiledev_feature3_h3: 'Integração com API e Backend',
          mobiledev_feature3_p: 'Integramos seu app com APIs e sistemas backend para garantir funcionalidades completas.',
          mobiledev_projects_h2: 'Projetos Recentes',
          project1_alt: 'App 1',
          project1_title: 'Aplicativo Fitness',
          project1_description: 'Desenvolvemos um app fitness para monitorar atividades físicas e nutrição, com integração com wearables.',
          project2_alt: 'App 2',
          project2_title: 'Plataforma de Delivery',
          project2_description: 'Criamos um app de delivery intuitivo e rápido, com integração de pagamento e rastreamento de pedidos em tempo real.',
          project3_alt: 'App 3',
          project3_title: 'Aplicativo de Educação',
          project3_description: 'Uma plataforma educacional interativa com cursos online, quizzes e certificações digitais para estudantes e profissionais.',
          project4_alt: 'App 4',
          project4_title: 'Aplicativo de Finanças',
          project4_description: 'Um app de finanças pessoais com análise de gastos, criação de orçamentos e alertas personalizados para economia.',
          testimonials_h2: 'Depoimentos',
          testimonial1_p: '"O app desenvolvido pela Nexus melhorou significativamente a experiência dos nossos usuários. Ótima performance!"',
          testimonial1_author: '— Cliente Satisfeito',
          cta_h2: 'Precisa de um App para Seu Negócio?',
          cta_button: 'Entre em Contato',
          uiux_h1: 'Design UI/UX',
          uiux_hero_p: 'Criamos interfaces intuitivas e experiências que encantam os usuários.',
          uiux_offer_h2: 'O que Oferecemos',
          uiux_offer_p: 'Na Nexus, acreditamos que o design vai além de um visual bonito. Criamos interfaces intuitivas e funcionais que proporcionam experiências de usuário excepcionais. Nossa abordagem centrada no usuário garante que cada interação seja fluida e eficiente.',
          uiux_feature1_h3: 'Design Centrado no Usuário',
          uiux_feature1_p: 'Utilizamos práticas de design thinking para garantir que o foco esteja sempre nas necessidades do usuário final.',
          uiux_feature2_h3: 'Prototipagem Rápida',
          uiux_feature2_p: 'Testamos ideias rapidamente com protótipos interativos para validação de conceitos antes do desenvolvimento.',
          uiux_feature3_h3: 'Design Responsivo',
          uiux_feature3_p: 'Criamos interfaces que se adaptam a qualquer dispositivo, proporcionando uma experiência consistente.',
          uiux_projects_h2: 'Projetos Recentes',
          project1_alt: 'Projeto UI/UX 1',
          project1_title: 'App de E-commerce',
          project1_description: 'Desenvolvemos o design de um aplicativo de e-commerce focado na experiência do usuário, aumentando as taxas de conversão.',
          project2_alt: 'Projeto UI/UX 2',
          project2_title: 'Dashboard Administrativo',
          project2_description: 'Criação de um dashboard intuitivo para facilitar a gestão de dados e tarefas em tempo real.',
          testimonials_h2: 'Depoimentos',
          testimonial1_p: '"O design UI/UX da Nexus elevou nosso produto a outro nível. Os usuários adoraram a nova experiência!"',
          testimonial1_author: '— Cliente Satisfeito',
          cta_h2: 'Quer melhorar a experiência do seu usuário?',
          cta_button: 'Entre em Contato',
          consultoria_h1: 'Consultoria Tecnológica',
          consultoria_hero_p: 'Ajudamos a sua empresa a encontrar as melhores soluções tecnológicas para crescer de forma eficiente.',
          consultoria_offer_h2: 'O que Oferecemos',
          consultoria_offer_p: 'Na Nexus, oferecemos consultoria tecnológica para ajudar sua empresa a encontrar e implementar soluções eficientes. Nossa equipe trabalha para entender suas necessidades e criar um plano estratégico com as melhores práticas do mercado.',
          consultoria_feature1_h3: 'Diagnóstico de Necessidades',
          consultoria_feature1_p: 'Analisamos o cenário atual do seu negócio e identificamos oportunidades para otimização com tecnologia.',
          consultoria_feature2_h3: 'Estratégias Personalizadas',
          consultoria_feature2_p: 'Criamos um plano de ação com soluções tecnológicas sob medida para seus desafios específicos.',
          consultoria_feature3_h3: 'Implementação e Suporte',
          consultoria_feature3_p: 'Fornecemos suporte completo durante a implementação e treinamento para sua equipe.',
          consultoria_projects_h2: 'Projetos Recentes',
          project1_alt: 'Projeto Consultoria 1',
          project1_title: 'Automatização de Processos',
          project1_description: 'Ajudamos uma empresa a automatizar seus processos internos, reduzindo o tempo gasto em tarefas manuais.',
          project2_alt: 'Projeto Consultoria 2',
          project2_title: 'Integração de Sistemas',
          project2_description: 'Desenvolvemos uma solução personalizada para integrar diferentes sistemas em uma única plataforma, facilitando a gestão de dados.',
          testimonials_h2: 'Depoimentos',
          testimonial1_p: '"A consultoria da Nexus foi essencial para a modernização da nossa infraestrutura tecnológica. Eles realmente entenderam nossas necessidades e entregaram soluções eficazes."',
          testimonial1_author: '— Cliente Satisfeito',
          cta_h2: 'Precisa de uma consultoria tecnológica?',
          cta_button: 'Entre em Contato',
        },
      },
    },
    lng: 'pt', // Idioma padrão
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

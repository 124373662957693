import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções
 
  return (
    <FooterContainer>
      <FooterTop>
        <NavLinks>
          <h3> { t('footer_h3') }</h3>
          <ul>
            <li><a href="/">{ t('home') }</a></li>
            <li><a href="/about">{ t('about') }</a></li>
            <li><a href="/services">{ t('services') }</a></li>
            <li><a href="/portfolio">Portfolio</a></li>
            <li><a href="/contact">{ t('contact') }</a></li>
          </ul>
        </NavLinks>

        <ContactInfo>
          <h3>{ t('contact') }</h3>
          <p>{ t('email') }: <a href="mailto:contato@nexusoficial.com">contato@nexusoficial.com</a></p>
          <p>{ t('telefone') }: <a href="tel:+123456789">+55 (85) 98122-0308</a></p>
          <p>{ t('endereço') }: R. Cássius Klay, 123 - São Bento, Fortaleza - CE, 60875-610</p>
        </ContactInfo>

        <SocialLinks>
          <h3>{ t('siganos') }</h3>
          <ul>
            <li><a href="https://www.facebook.com/bynexusoficial" target="_blank" rel="noopener noreferrer">Facebook</a></li>
            <li><a href="https://www.instagram.com/bynexusoficial" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          </ul>
        </SocialLinks>
      </FooterTop>

      <FooterBottom>
        <p>{ t('footerBottom') }</p>
      </FooterBottom>
    </FooterContainer>
  );
};

// Estilização com styled-components

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.background};
  color: white;
  padding: 60px 20px; /* Diminui o padding geral para o mobile */
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterTop = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px; /* Ajuste do gap entre colunas */
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 20px; /* Reduzi o gap no mobile */
    margin-bottom: 20px; /* Reduzi o margin-bottom no mobile */
  }
`;

const NavLinks = styled.div`
  margin: 0 0 0 40px;

  @media (max-width: 768px) {
    margin: 0; /* Remove a margem lateral no mobile */
  }

  h3 {
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.accent};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 10px; /* Ajuste no espaçamento */

      a {
        color: white;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: ${({ theme }) => theme.colors.accent};
        }
      }
    }
  }
`;

const ContactInfo = styled.div`
  margin: 0 40px 0 0;

  @media (max-width: 768px) {
    margin: 0; /* Remove a margem lateral no mobile */
  }

  h3 {
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.accent};
  }

  p {
    margin: 10px 0;

    a {
      color: white;
      text-decoration: none;
      transition: color 0.3s;

      &:hover {
        color: ${({ theme }) => theme.colors.accent};
      }
    }
  }
`;

const SocialLinks = styled.div`
  margin: 0 0 0 40px;

  @media (max-width: 768px) {
    margin: 0; /* Remove a margem lateral no mobile */
  }

  h3 {
    margin-bottom: 15px;
    color: ${({ theme }) => theme.colors.accent};
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 10px;

      a {
        color: white;
        text-decoration: none;
        transition: color 0.3s;

        &:hover {
          color: ${({ theme }) => theme.colors.accent};
        }
      }
    }
  }
`;

const FooterBottom = styled.div`
  padding-top: 30px; /* Ajuste no espaçamento superior */
  border-top: 1px solid #fff;
  text-align: center;
  width: 100%;

  p {
    margin: 0;
    font-size: 0.9rem;
  }
`;

export default Footer;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Services = () => {
  const navigate = useNavigate(); // Hook para navegação
  const { t } = useTranslation(); // Hook para acessar as traduções

  const handleLearnMore = (path) => {
    navigate(path); // Redireciona para a página correspondente
  };

  return (
    <ServicesContainer>
      <HeroSection 
        as={motion.section} 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 1 }}
      >
        <h1>{t('services_h1')}</h1>
        <p>{t('services_description')}</p>
      </HeroSection>

      <CardsContainer>
        <ServiceCard 
          as={motion.div}
          initial={{ opacity: 0, y: 50 }} 
          whileInView={{ opacity: 1, y: 0 }} 
          transition={{ duration: 0.8 }}
        >
          <img src={require('../assets/web-development.png')} alt={t('web_development_alt')} />
          <h3>{t('web_development_title')}</h3>
          <p>{t('web_development_description')}</p>
          <CTAButton onClick={() => handleLearnMore('/services/web-development')}>
            {t('learn_more_button')}
          </CTAButton>
        </ServiceCard>

        <ServiceCard 
          as={motion.div}
          initial={{ opacity: 0, y: 50 }} 
          whileInView={{ opacity: 1, y: 0 }} 
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <img src={require('../assets/mobile-development.png')} alt={t('mobile_development_alt')} />
          <h3>{t('mobile_development_title')}</h3>
          <p>{t('mobile_development_description')}</p>
          <CTAButton onClick={() => handleLearnMore('/services/mobile-development')}>
            {t('learn_more_button')}
          </CTAButton>
        </ServiceCard>

        <ServiceCard 
          as={motion.div}
          initial={{ opacity: 0, y: 50 }} 
          whileInView={{ opacity: 1, y: 0 }} 
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <img src={require('../assets/ui-ux.jpg')} alt={t('ui_ux_design_alt')} />
          <h3>{t('ui_ux_design_title')}</h3>
          <p>{t('ui_ux_design_description')}</p>
          <CTAButton onClick={() => handleLearnMore('/services/ui-ux')}>
            {t('learn_more_button')}
          </CTAButton>
        </ServiceCard>

        <ServiceCard 
          as={motion.div}
          initial={{ opacity: 0, y: 50 }} 
          whileInView={{ opacity: 1, y: 0 }} 
          transition={{ duration: 0.8, delay: 0.6 }}
        >
          <img src={require('../assets/consultoria.jpg')} alt={t('consulting_alt')} />
          <h3>{t('consulting_title')}</h3>
          <p>{t('consulting_description')}</p>
          <CTAButton onClick={() => handleLearnMore('/services/consultoria')}>
            {t('learn_more_button')}
          </CTAButton>
        </ServiceCard>
      </CardsContainer>
    </ServicesContainer>
  );
};

// Estilização com styled-components
const ServicesContainer = styled.div`
  padding: 60px 20px;
  background-color: #f9f9f9;
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 80px 20px;
  background: linear-gradient(135deg, ${({ theme }) => theme.colors.background}, ${({ theme }) => theme.colors.background_second});
  color: ${({ theme }) => theme.colors.white}; 

  h1 {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white}; 
    margin-bottom: 20px;
  }

  p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
    max-width: 600px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 60px 10px;
    h1 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  padding: 40px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ServiceCard = styled(motion.div)`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 280px;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CTAButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.background}; 
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background_second}; 
    color: ${({ theme }) => theme.colors.background}; 
  }
`;

export default Services;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const InfoSection = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções

  return (
    <SectionContainer>
      <MainTextContainer
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h2>{ t ('infoSection_h2')}</h2>
        <p>{ t ('infoSection_p_1')}</p>
      </MainTextContainer>
      
      <GridContainer>
        <InfoBlock
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          whileHover={{ scale: 1.05 }} // Adicionando um efeito de hover
        >
          <h3>{ t ('infoSection_h3_1')}</h3>
          <p>{ t ('infoSection_p_2')} </p>
        </InfoBlock>

        <InfoBlock
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          whileHover={{ scale: 1.05 }} // Adicionando um efeito de hover
        >
          <h3>{ t ('infoSection_h3_2')}</h3>
          <p>{ t ('infoSection_p_3')}</p>
        </InfoBlock>

        <InfoBlock
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6 }}
          whileHover={{ scale: 1.05 }} // Adicionando um efeito de hover
        >
          <h3>{ t ('infoSection_h3_3')}</h3>
          <p>{ t ('infoSection_p_4')}</p>
        </InfoBlock>
      </GridContainer>
    </SectionContainer>
  );
};

// Estilização com styled-components

const SectionContainer = styled.section`
  background-color: #f5f5f5;
  padding: 60px 20px;
  text-align: center;
`;

const MainTextContainer = styled(motion.div)`
  margin-bottom: 40px;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
    color: #666;
  }
`;

const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoBlock = styled(motion.div)`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease-in-out; // Transição suave para hover

  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #333;
  }

  p {
    font-size: 1rem;
    color: #666;
  }

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); // Sombra mais forte no hover
  }
`;

export default InfoSection;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const Projects = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções
  
  const projectList = [
    {
      title: t('project_ecommerce_title'),
      description: t('project_ecommerce_description'),
      image: require('../assets/file.png'),
      details: t('project_ecommerce_details'),
    },
    {
      title: t('project_delivery_title'),
      description: t('project_delivery_description'),
      image: require('../assets/Thumbnail.png'),
      details: t('project_delivery_details'),
    },
    {
      title: t('project_solar_title'),
      description: t('project_solar_description'),
      image: require('../assets/image.png'),
      details: t('project_solar_details'),
    },
    {
      title: t('project_medicine_title'),
      description: t('project_medicine_description'),
      image: require('../assets/medicine.jpg'),
      details: t('project_medicine_details'),
    },
    {
      title: t('project_finance_title'),
      description: t('project_finance_description'),
      image: require('../assets/image_2.png'),
      details: t('project_finance_details'),
    }
  ];

  return (
    <ProjectsContainer>
      <h1>{t('projects_h1')}</h1>
      <ProjectsGrid>
        {projectList.map((project, index) => (
          <ProjectCard key={index} project={project} />
        ))}
      </ProjectsGrid>
    </ProjectsContainer>
  );
};

const ProjectCard = ({ project }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleDetails = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Card>
      <img src={project.image} alt={project.title} />
      <ProjectInfo>
        <h3>{project.title}</h3>
        <p>{project.description}</p>
        {isExpanded && <Details>{project.details}</Details>}
        <ProjectButton onClick={handleToggleDetails}>
          {isExpanded ? 'Ver Menos' : 'Ver Detalhes'}
        </ProjectButton>
      </ProjectInfo>
    </Card>
  );
};

// Estilização com styled-components

const ProjectsContainer = styled.div`
  padding: 60px 20px;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background_second}; 

  h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const Card = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
  }

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
`;

const ProjectInfo = styled.div`
  padding: 20px;

  h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: #666;
  }
`;

const Details = styled.div`
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
`;

const ProjectButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

export default Projects;

import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CTASection = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções
  const handleWhatsAppClick = () => {
    window.open('https://wa.me/558581220308', '_blank');
  };

  return (
    <SectionContainer
      as={motion.section}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: 'easeOut' }}
    >
      <ContentContainer>
        <motion.h2
          initial={{ textShadow: '0px 0px 8px rgba(0, 255, 255, 0.5)' }}
          animate={{ textShadow: '0px 0px 12px rgba(0, 255, 255, 0.8)' }}
          transition={{ repeat: Infinity, repeatType: 'reverse', duration: 2 }}
        >
          {t('cta_h2')}
        </motion.h2>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5, ease: 'easeInOut' }}
        >
          {t('cta_p')}
        </motion.p>
        <Link>
          <CTAButton
            as={motion.button}
            whileHover={{ scale: 1.1, boxShadow: '0px 0px 12px rgba(0, 255, 255, 0.8)' }}
            transition={{ type: 'spring', stiffness: 300 }}
            onClick={handleWhatsAppClick}
          >
            {t('cta_button')}
          </CTAButton>
        </Link>
      </ContentContainer>
      <ImageContainer
        as={motion.div}
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.5 }}
      >
        <img src={require("../assets/image_comp.jpg")} alt="Nexus em Ação" />
      </ImageContainer>
    </SectionContainer>
  );
};

const SectionContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 20px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: 60px 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  margin-left: 10%;
  padding: 60px;
  
  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colors.white};
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 1024px) {
    padding: 40px;
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
    padding: 20px;
    
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const CTAButton = styled.button`
  padding: 15px 25px;
  background-color: transparent;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background_second};
    color: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: 768px) {
    padding: 12px 20px;
    font-size: 1rem;
  }
`;

const ImageContainer = styled.div`
  width: 40%;
  max-width: 400px;
  margin-right: 10%;

  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 768px) {
    margin-top: 20px;
    width: 80%;
    margin-right: 0;
  }
`;

export default CTASection;

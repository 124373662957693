import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa'; // Ícones de hambúrguer e fechar
import logo from '../assets/logo_color.png';
import LanguageSwitcher from './LanguageSwitcher';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções
  const [scrolled, setScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Nav scrolled={scrolled}>
      <Logo>
        <Link to="/">
          <img src={logo} alt="Nexus Logo" />
        </Link>
      </Logo>
      <Hamburger onClick={toggleMenu}>
        {menuOpen ? <FaTimes /> : <FaBars />}
      </Hamburger>
      <NavMenu menuOpen={menuOpen}>
        <StyledLink to="/" scrolled={scrolled} onClick={toggleMenu}>{t('home')}</StyledLink>
        <StyledLink to="/about" scrolled={scrolled} onClick={toggleMenu}>{t('about')}</StyledLink>
        <StyledLink to="/services" scrolled={scrolled} onClick={toggleMenu}>{t('services')}</StyledLink>
        <StyledLink to="/portfolio" scrolled={scrolled} onClick={toggleMenu}>Portfolio</StyledLink>
        <StyledLink to="/contact" scrolled={scrolled} onClick={toggleMenu}>{t('contact')}</StyledLink>
        <StyledLink to="/orcamento" scrolled={scrolled} onClick={toggleMenu}>{t('orcamento')}</StyledLink>
      </NavMenu>
      <LanguageSwitcher /> {/* Adicionando o seletor de idioma ao Header */}
    </Nav>
  );
};

// Estilização com styled-components

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: ${({ scrolled, theme }) =>
    scrolled ? theme.colors.background_second : theme.colors.white}; // Muda a cor ao rolar
  transition: background-color 0.3s ease-in-out;
  box-shadow: ${({ scrolled }) => (scrolled ? '0 2px 10px rgba(0, 0, 0, 0.1)' : 'none')};
  position: relative;
`;

const Logo = styled.div`
  img {
    height: 50px;
  }
`;

const Hamburger = styled.div`
  display: none;
  font-size: 2rem;
  cursor: pointer;
  z-index: 100; /* Para garantir que fique acima do menu */

  @media (max-width: 768px) {
    display: block;
  }
`;

const NavMenu = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    display: ${({ menuOpen }) => (menuOpen ? 'block' : 'none')}; /* Mostra o menu se estiver aberto */
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background};
    padding: 20px;
    text-align: center;
  }
`;

const StyledLink = styled(Link)`
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ scrolled, theme }) => (scrolled ? theme.colors.white : theme.colors.background)};
  transition: color 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    display: block;
    padding: 10px 0;
    color: white; /* Cor branca para o menu mobile */
    font-size: 1.5rem;
  }
`;

export default Header;

import React from 'react';
import styled from 'styled-components';
import CalloutBanner from '../components/Banner';
import InfoSection from '../components/InfoSection';
import ClientsSection from '../components/ClientsSection';
import CTASection from '../components/CTASection';
import Hero from '../components/Hero';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções

  return (
    <>
    <Hero/>
    <CalloutBanner/>
    <Section id="about-section">
        <h2>{t('home_h2')}</h2>
        <CardGrid>
          <Card>
          <img src= {require('../assets/image_mobile.png')} alt="Service 2" />
            <h3>Mob Nexus</h3>
            <p>{t('home_mob_p')}</p>
            <Link to="/services/mobile-development"> {/* Link para a página inicial */}
              <CTAButton>{t('button_descubra_mais')}</CTAButton>
            </Link>
          </Card>
          <Card>
            <img src= {require('../assets/image_web.png')} alt="Service 2" />
            <h3>Web Nexus</h3>
            <p>{t('home_web_p')}</p>
            <Link to="/services/web-development"> {/* Link para a página inicial */}
              <CTAButton >{t('button_descubra_mais')}</CTAButton>
            </Link>
          </Card>
        </CardGrid>
    </Section>
    <CTASection/>
    <ClientsSection/>
    <InfoSection/>
    </>
  );
};

// Estilização com styled-components

const CTAButton = styled.button`
  padding: 20px 40px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  margin-bottom: 30px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background}; 
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-width: 768px) {
    padding: 15px 30px; /* Reduz o padding do botão em telas menores */
    font-size: 1rem; /* Ajusta o tamanho da fonte do botão */
  }
`;

const Section = styled.section`
  padding: 60px 20px;
  background-color: #fff;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`;

const Card = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }

  h3 {
    font-size: 1.8rem;
    margin: 20px 0;
    color :  ${({ theme }) => theme.colors.primary}; // Texto branco
  }

  p {
    font-size: 1.2rem;
    margin: 10px 20px;
  }
`;

export default Home;

const theme = {
    colors: {
      primary: '#000103', // Azul escuro
      secondary: '#00A3FF', // Azul claro
      accent: '#FDB827', // Verde claro
      background: '#0C056D', // Fundo
      background_second: '#f5f5f5', // Fundo
      text: '#333', // Cor do texto
      white: '#FFFFFF', // Branco
    },
    fonts: {
      main: 'Poppins, sans-serif', // Fonte principal
      secondary: 'Roboto, sans-serif', // Fonte secundária
    },
  };
  
  export default theme;
  
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Consultoria = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções

  return (
    <ServiceContainer>
      <HeroSection 
        as={motion.section} 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ duration: 1 }}
      >
        <h1>{t('consultoria_h1')}</h1>
        <p>{t('consultoria_hero_p')}</p>
      </HeroSection>

      <Section
        as={motion.section}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h2>{t('consultoria_offer_h2')}</h2>
        <p>{t('consultoria_offer_p')}</p>
      </Section>

      <FeaturesContainer>
        <FeatureBox>
          <h3>{t('consultoria_feature1_h3')}</h3>
          <p>{t('consultoria_feature1_p')}</p>
        </FeatureBox>
        <FeatureBox>
          <h3>{t('consultoria_feature2_h3')}</h3>
          <p>{t('consultoria_feature2_p')}</p>
        </FeatureBox>
        <FeatureBox>
          <h3>{t('consultoria_feature3_h3')}</h3>
          <p>{t('consultoria_feature3_p')}</p>
        </FeatureBox>
      </FeaturesContainer>

      <Section
        as={motion.section}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h2>{t('consultoria_projects_h2')}</h2>
        <ProjectContainer>
          <ProjectCard>
            <img src={require('../assets/consultoria.jpg')} alt={t('project1_alt')} />
            <h3>{t('project1_title')}</h3>
            <p>{t('project1_description')}</p>
          </ProjectCard>
          <ProjectCard>
            <img src={require('../assets/consultoria.jpg')} alt={t('project2_alt')} />
            <h3>{t('project2_title')}</h3>
            <p>{t('project2_description')}</p>
          </ProjectCard>
        </ProjectContainer>
      </Section>

      <Section
        as={motion.section}
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <h2>{t('testimonials_h2')}</h2>
        <TestimonialContainer>
          <TestimonialCard>
            <p>{t('testimonial1_p')}</p>
            <h4>{t('testimonial1_author')}</h4>
          </TestimonialCard>
        </TestimonialContainer>
      </Section>

      <CTASection>
        <h2>{t('cta_h2')}</h2>
        <CTAButton>{t('cta_button')}</CTAButton>
      </CTASection>
    </ServiceContainer>
  );
};

// Estilos com styled-components
const ServiceContainer = styled.div`
  padding: 60px 20px;
  background-color: #f9f9f9;
`;

const HeroSection = styled.section`
  text-align: center;
  padding: 80px;
  background: linear-gradient(135deg, ${({ theme }) => theme.colors.background}, ${({ theme }) => theme.colors.background_second});
  color: ${({ theme }) => theme.colors.white}; 

  h1 {
    font-size: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white}; 
  }

  p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
    max-width: 600px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 60px 20px;
    h1 {
      font-size: 2.2rem;
    }
    p {
      font-size: 1.2rem;
    }
  }
`;

const Section = styled.section`
  padding: 60px 0;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 40px 20px;
    h2 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }
`;

const FeaturesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 40px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FeatureBox = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    width: 80%;
    margin-bottom: 20px;
  }
`;

const ProjectContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 40px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ProjectCard = styled.div`
  width: 280px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;

  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const TestimonialContainer = styled.div`
  padding: 40px;
  text-align: center;
`;

const TestimonialCard = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
`;

const CTASection = styled.section`
  text-align: center;
  padding: 60px 0;
  background-color: ${({ theme }) => theme.colors.primary};

  h2 {
    color: ${({ theme }) => theme.colors.white};
    font-size: 2rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white}; 
  }
`;

const CTAButton = styled.button`
  background-color: ${({ theme }) => theme.colors.background}; 
  color: ${({ theme }) => theme.colors.white}; 
  padding: 20px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.2rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background_second}; 
    color: ${({ theme }) => theme.colors.background}; 
  }
`;

export default Consultoria;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation(); // Hook para acessar as traduções

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (isSubmitted || isError) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
        setIsError(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted, isError]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: name,
      from_email: email,
      message: message,
    };

    emailjs
      .send('service_rlk9yyr', 'template_g01g4o1', templateParams, 'Gn5B8_JLn3uqj9YfY')
      .then(
        (response) => {
          setIsSubmitted(true);
          setIsError(false);
          setName('');
          setEmail('');
          setMessage('');
        },
        (error) => {
          setIsSubmitted(false);
          setIsError(true);
        }
      );
  };

  return (
    <ContactContainer>
      <FormContainer>
        <h2>{t('contact_h2')}</h2>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <label htmlFor="name">{t('name_label')}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="message">{t('message_label')}</label>
            <textarea
              id="message"
              name="message"
              rows="5"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </FormGroup>
          <SubmitButton type="submit">{t('send_message_button')}</SubmitButton>
        </form>

        {isSubmitted && (
          <SuccessMessage>{t('success_message')}</SuccessMessage>
        )}
        {isError && (
          <ErrorMessage>{t('error_message')}</ErrorMessage>
        )}
      </FormContainer>

      <InfoContainer>
        <h2>{t('contact_direct')}</h2>
        <p>{t('phone')}: +55 (85) 98122-0308</p>
        <p>Email: contato@nexusoficial.com</p>
        <h3>{t('visit_us')}:</h3>
        <p>R. Cássius Klay, 123 - São Bento, Fortaleza - CE, 60875-610</p>
      </InfoContainer>
    </ContactContainer>
  );
};

// Estilos com styled-components

const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 60px 20px;
  background-color: #f9f9f9;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FormContainer = styled.div`
  width: 48%;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  label {
    margin-bottom: 10px;
    font-weight: bold;
  }

  input, textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }

  textarea {
    resize: none;
  }
`;

const SubmitButton = styled.button`
  background-color: #000;
  color: #fff;
  padding: 20px 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 1.2rem;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background}; 
    color: ${({ theme }) => theme.colors.white}; 
  }
`;

const InfoContainer = styled.div`
  width: 48%;
  padding: 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SuccessMessage = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #84bc9c;
  color: white;
  border-radius: 5px;
`;

const ErrorMessage = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #dc3545;
  color: white;
  border-radius: 5px;
`;

export default Contact;

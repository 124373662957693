import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const SimulacaoOrcamento = () => {
  const { t } = useTranslation();

  const [categoria, setCategoria] = useState('');
  const [publicoAlvo, setPublicoAlvo] = useState('');
  const [funcionalidade, setFuncionalidade] = useState('');
  const [integracao, setIntegracao] = useState('');
  const [usuariosMensais, setUsuariosMensais] = useState('');
  const [restricaoTecnologica, setRestricaoTecnologica] = useState('');
  const [apiExterna, setApiExterna] = useState('');
  const [designNecessario, setDesignNecessario] = useState('');
  const [tipoIntegracao, setTipoIntegracao] = useState('');
  const [outrosIntegracao, setOutrosIntegracao] = useState('');
  const [tipoServico, setTipoServico] = useState('');
  const [valorTotal, setValorTotal] = useState(0); 
  const [urgencia, setUrgencia] = useState(''); // Novo campo para urgência
 
  const [mobileOptions, setMobileOptions] = useState({
    plataforma: '',
    notificacoes: '',
    funcionalidades: '',
    dispositivosExternos: '',
    acessoInternet: '',
    painelAdministracao: '',
  });
  const [webOptions, setWebOptions] = useState({
    multilanguage: '',
    funcionalidades: '',
    login: '',
    pagamento: '',
    seo: '',
    performance: '',
    paginas: '',
  });
  const [consultoriaOptions, setConsultoriaOptions] = useState({
    equipe: '',
    tecnologia: '',
    objetivo: '',
    acompanhamento: '',
  });

// Função para calcular o valor baseado nas escolhas
  const calcularValorTotal = () => {
    let valorBase = 0; // Um valor base inicial para qualquer serviço
    if (tipoServico === 'Desenvolvimento Web') valorBase += 2000;
    if (tipoServico === 'Desenvolvimento Mobile') valorBase += 3000;
    if (tipoServico === 'Desenvolvimento Web + Mobile') valorBase += 6000;
    if (tipoServico === 'Consultoria') valorBase += 1500;

    // Adiciona valor para as opções de mobile
    if (mobileOptions.plataforma === 'Ambos') valorBase += 2000;
    if (mobileOptions.plataforma === 'iOS') valorBase += 1000;
    if (mobileOptions.plataforma === 'Android') valorBase += 1000;
    if (mobileOptions.notificacoes === 'Sim') valorBase += 500;
    if (mobileOptions.painelAdministracao === 'Sim') valorBase += 1000;
    if (mobileOptions.dispositivosExternos === 'Sim') valorBase += 800;
    if (mobileOptions.acessoInternet === 'Sim') valorBase += 500;
    
    // Adiciona valor para as opções de web
    if (webOptions.pagamento === 'Sim') valorBase += 1000;
    if (webOptions.multilanguage === 'Sim') valorBase += 1200;
    if (webOptions.login === "Sim") valorBase += 900;
    if (webOptions.performance === "Sim") valorBase += 900;
    if (webOptions.funcionalidades.includes('Blog')) valorBase += 700;
    if (webOptions.funcionalidades.includes('E-commerce')) valorBase += 1500;
    if (webOptions.funcionalidades.includes('Portal de Notícias')) valorBase += 1000;
    if (webOptions.seo.includes('Básico')) valorBase += 300;
    if (webOptions.seo.includes('Avançado')) valorBase += 800;

    if (integracao === 'Sim') valorBase += 600;
   
    // Aumenta valor baseado na urgência
    if (urgencia === 'Urgente') {
        valorBase *= 1.5; // Adiciona 30% ao valor total se for urgente
    }
    // Aumenta valor com base no número de páginas no desenvolvimento web
    valorBase += webOptions.paginas * 100;

    setValorTotal(valorBase);
  };


  const handleTipoServicoChange = (e) => {
    setTipoServico(e.target.value);
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
  
    setWebOptions((prev) => {
      if (checked) {
        // Adiciona a opção selecionada
        return { ...prev, funcionalidades: [...prev.funcionalidades, value] };
      } else {
        // Remove a opção desmarcada
        return {
          ...prev,
          funcionalidades: prev.funcionalidades.filter((f) => f !== value),
        };
      }
    });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const dadosSimulacao = `
      Olá, gostaria de um orçamento mais detalhado.

      Categoria: ${categoria || 'Não especificado'}
      Público-Alvo: ${publicoAlvo || 'Não especificado'}
      Funcionalidade: ${funcionalidade || 'Não especificado'}
      Integração: ${integracao || 'Não especificado'}
      Tipo de Integração: ${tipoIntegracao || 'Não especificado'}
      Usuários Mensais: ${usuariosMensais || 'Não especificado'}
      Restrição Tecnológica: ${restricaoTecnologica || 'Não especificado'}
      API Externa: ${apiExterna || 'Não especificado'}
      Design Necessário: ${designNecessario || 'Não especificado'}
      Tipo de Serviço: ${tipoServico || 'Não especificado'}
      Plataforma: ${mobileOptions.plataforma || 'Não especificado'}
      Notificações Push: ${mobileOptions.notificacoes || 'Não especificado'}
      Funcionalidades Mobile: ${mobileOptions.funcionalidades || 'Não especificado'}
      Integração com Dispositivos Externos: ${mobileOptions.dispositivosExternos || 'Não especificado'}
      Acesso Internet Limitado: ${mobileOptions.acessoInternet || 'Não especificado'}
      Painel de Administração: ${mobileOptions.painelAdministracao || 'Não especificado'}
      Funcionalidades Web: ${(Array.isArray(webOptions.funcionalidades) ? webOptions.funcionalidades.join(', ') : webOptions.funcionalidades || 'Não especificado')}
      Login: ${webOptions.login || 'Não especificado'}
      Pagamento: ${webOptions.pagamento || 'Não especificado'}
      SEO: ${webOptions.seo || 'Não especificado'}
      Performance: ${webOptions.performance || 'Não especificado'}
      Páginas: ${webOptions.paginas || 'Não especificado'}
      Urgência: ${urgencia || 'Não especificado'}
      Valor Estimado: R$ ${valorTotal.toFixed(2)}
    `;
  
    // URL do WhatsApp com mensagem
    const whatsappUrl = `https://wa.me/5585981220308?text=${encodeURIComponent(dadosSimulacao)}`;
  
    // Redireciona para o WhatsApp
    window.open(whatsappUrl, '_blank');
  };
  

  const handleIntegracaoChange = (e) => {
    const value = e.target.value;
    setIntegracao(value);

    if (value !== 'Sim') {
      setTipoIntegracao('');
      setOutrosIntegracao('');
    }
  };

  useEffect(() => {
    calcularValorTotal(); // Recalcula o valor total sempre que as opções mudarem
  }, [tipoServico, mobileOptions, webOptions,urgencia]);

  return (
    <SimulacaoContainer>
      <h2>{t('Simulação de Orçamento')}</h2>
         {/* Exibição do valor calculado */}
         <TotalContainer>
          <h3>{t('Valor estimado do projeto')}:</h3>
          <Valor>{`R$ ${valorTotal.toFixed(2)}`}</Valor>
        </TotalContainer>

      <Form onSubmit={handleSubmit}>
      <FormGroup>
        <label htmlFor="tipoServico">{t('Tipo de Serviço')}</label>
        <select
          id="tipoServico"
          value={tipoServico}
          onChange={handleTipoServicoChange}
          required
        >
          <option value="">{t('Selecione')}</option>
          <option value="Desenvolvimento Web">{t('Desenvolvimento Web')}</option>
          <option value="Desenvolvimento Web + Mobile">{t('Desenvolvimento Web + Mobile')}</option>
          <option value="Desenvolvimento Mobile">{t('Desenvolvimento Mobile')}</option>
          <option value="Consultoria">{t('Consultoria')}</option>
        </select>

         {/* Se a escolha for Desenvolvimento Mobile */}
         {(tipoServico === 'Desenvolvimento Mobile' || tipoServico === 'Desenvolvimento Web + Mobile') && (

            <div>
           <FormGroup>
            <label>{t('O aplicativo será para qual sistema operacional?')}</label>
            <select
                value={mobileOptions.plataforma}
                onChange={(e) => setMobileOptions({ ...mobileOptions, plataforma: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Android">Android</option>
                <option value="iOS">iOS</option>
                <option value="Ambos">Ambos</option>
            </select>
            </FormGroup>

            <FormGroup>
                <label>{t('O app precisará de notificações push?')}</label>
                <select
                    value={mobileOptions.notificacoes}
                    onChange={(e) => setMobileOptions({ ...mobileOptions, notificacoes: e.target.value })}
                    required
                >
                    <option value="">{t('Selecione')}</option>
                    <option value="Sim">{t('Sim')}</option>
                    <option value="Não">{t('Não')}</option>
                </select>
            </FormGroup>

            <FormGroup>
            <label>{t('Quais funcionalidades principais você espera no app?')}</label>
            <textarea
                value={mobileOptions.funcionalidades}
                onChange={(e) => setMobileOptions({ ...mobileOptions, funcionalidades: e.target.value })}
            />
            </FormGroup>

            <FormGroup>
            <label>{t('O app precisará de integração com dispositivos externos?')}</label>
            <select
                value={mobileOptions.dispositivosExternos}
                onChange={(e) => setMobileOptions({ ...mobileOptions, dispositivosExternos: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Sim">{t('Sim')}</option>
                <option value="Não">{t('Não')}</option>
            </select>
            </FormGroup>

            <FormGroup>
            <label>{t('O app será usado em áreas com acesso limitado à internet?')}</label>
            <select
                value={mobileOptions.acessoInternet}
                onChange={(e) => setMobileOptions({ ...mobileOptions, acessoInternet: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Sim">{t('Sim')}</option>
                <option value="Não">{t('Não')}</option>
            </select>
            </FormGroup>

            <FormGroup>
            <label>{t('Há necessidade de um painel de administração para o app?')}</label>
            <select
                value={mobileOptions.painelAdministracao}
                onChange={(e) => setMobileOptions({ ...mobileOptions, painelAdministracao: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Sim">{t('Sim')}</option>
                <option value="Não">{t('Não')}</option>
            </select>
            </FormGroup>
        </div>
        )}

        {/* Se a escolha for Desenvolvimento Web */}
        {(tipoServico === 'Desenvolvimento Web' || tipoServico === 'Desenvolvimento Web + Mobile') && (

            <div>
            <FormGroup>
            <label>{t('O site precisa ser multilíngue?')}</label>
            <select
                value={webOptions.multilanguage}
                onChange={(e) => setWebOptions({ ...webOptions, multilanguage: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Sim">{t('Sim')}</option>
                <option value="Não">{t('Não')}</option>
            </select>
            </FormGroup>

            <FormGroup>
            <label>{t('O site incluirá blog, e-commerce ou portal de notícias?')}</label>
            <div>
                <label>
                <input
                    type="checkbox"
                    value="Blog"
                    checked={webOptions.funcionalidades.includes('Blog')}
                    onChange={handleCheckboxChange}
                />
                {t('Blog')}
                </label>
                <label>
                <input
                    type="checkbox"
                    value="E-commerce"
                    checked={webOptions.funcionalidades.includes('E-commerce')}
                    onChange={handleCheckboxChange}
                />
                {t('E-commerce')}
                </label>
                <label>
                <input
                    type="checkbox"
                    value="Portal de Notícias"
                    checked={webOptions.funcionalidades.includes('Portal de Notícias')}
                    onChange={handleCheckboxChange}
                />
                {t('Portal de Notícias')}
                </label>
            </div>
            </FormGroup>

            <FormGroup>
            <label>{t('Há necessidade de sistema de login e perfis de usuário?')}</label>
            <select
                value={webOptions.login}
                onChange={(e) => setWebOptions({ ...webOptions, login: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Sim">{t('Sim')}</option>
                <option value="Não">{t('Não')}</option>
            </select>
            </FormGroup>

            <FormGroup>
            <label>{t('Deseja integração com meios de pagamento?')}</label>
            <select
                value={webOptions.pagamento}
                onChange={(e) => setWebOptions({ ...webOptions, pagamento: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Sim">{t('Sim')}</option>
                <option value="Não">{t('Não')}</option>
            </select>
            </FormGroup>

            <FormGroup>
            <label>{t('Qual o nível de SEO desejado?')}</label>
            <select
                value={webOptions.seo}
                onChange={(e) => setWebOptions({ ...webOptions, seo: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Básico">{t('Básico')}</option>
                <option value="Avançado">{t('Avançado')}</option>
            </select>
            </FormGroup>


            <FormGroup>
            <label>{t('Há necessidade de otimização para performance e velocidade?')}</label>
            <select
                value={webOptions.performance}
                onChange={(e) => setWebOptions({ ...webOptions, performance: e.target.value })}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Sim">{t('Sim')}</option>
                <option value="Não">{t('Não')}</option>
            </select>
            </FormGroup>


            <FormGroup>
                <label>{t('Quantas páginas o site terá?')}</label>
                <input
                type="number"
                value={webOptions.paginas}
                onChange={(e) => setWebOptions({ ...webOptions, paginas: e.target.value })}
                />
            </FormGroup>
            </div>
        )}

        {/* Se a escolha for Consultoria */}
        {tipoServico === 'Consultoria' && (
            <div>
            <FormGroup>
                <label>{t('Qual o tamanho da equipe que será treinada?')}</label>
                <input
                type="number"
                value={consultoriaOptions.equipe}
                onChange={(e) => setConsultoriaOptions({ ...consultoriaOptions, equipe: e.target.value })}
                />
            </FormGroup>

            <FormGroup>
                <label>{t('Você já utiliza alguma tecnologia que precisa de melhorias?')}</label>
                <select
                    value={consultoriaOptions.tecnologia}
                    onChange={(e) => setConsultoriaOptions({ ...consultoriaOptions, tecnologia: e.target.value })}
                    required
                >
                    <option value="">{t('Selecione')}</option>
                    <option value="Sim">{t('Sim')}</option>
                    <option value="Não">{t('Não')}</option>
                </select>
                </FormGroup>

                {consultoriaOptions.tecnologia === 'Sim' && (
                <FormGroup>
                    <label>{t('Se sim, qual tecnologia?')}</label>
                    <input
                    type="text"
                    value={consultoriaOptions.tecnologiaDetalhes}
                    onChange={(e) => setConsultoriaOptions({ ...consultoriaOptions, tecnologiaDetalhes: e.target.value })}
                    required
                    />
                </FormGroup>
                )}
            <FormGroup>
                <label>{t('Qual é o principal objetivo da consultoria?')}</label>
                <input
                type="text"
                value={consultoriaOptions.objetivo}
                onChange={(e) => setConsultoriaOptions({ ...consultoriaOptions, objetivo: e.target.value })}
                />
            </FormGroup>
                <FormGroup>
                <label>{t('Há necessidade de acompanhamento contínuo após a implementação?')}</label>
                <select
                    value={consultoriaOptions.acompanhamento}
                    onChange={(e) => setConsultoriaOptions({ ...consultoriaOptions, acompanhamento: e.target.value })}
                    required
                >
                    <option value="">{t('Selecione')}</option>
                    <option value="Sim">{t('Sim')}</option>
                    <option value="Não">{t('Não')}</option>
                </select>
                </FormGroup>
            </div>
        )}

      </FormGroup>
        <FormGroup>
          <label htmlFor="categoria">{t('Qual a categoria do projeto?')}</label>
          <select
            id="categoria"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
            required
          >
            <option value="">{t('Selecione uma categoria')}</option>
            <option value="Agronegócio"> Agronegócio </option> 
            <option value="Alimentação"> Alimentação/Delivery </option> 
            <option value="Arte"> Arte e Cultura</option>  
            <option value="Beleza"> Beleza e Estética </option>  
            <option value="Comércio"> Comércio/Varejo </option> 
            <option value="Consultoria"> Consultoria </option> 
            <option value="Construção"> Construção Civil</option> 
            <option value="Educação"> Educação </option> 
            <option value="Energia"> Energia </option> 
            <option value="Entretenimento"> Entretenimento </option> 
            <option value="Esportes"> Esportes </option> 
            <option value="Finanças"> Finanças </option> 
            <option value="Imobiliário"> Imobiliário </option> 
            <option value="Jurídico"> Jurídico </option> 
            <option value="Logística"> Logística </option> 
            <option value="Manufatura"> Manufatura </option> 
            <option value="Marketing"> Marketing/Publicidade </option> 
            <option value="Mídia"> Mídia e Jornalismo</option>  
            <option value="Moda"> Moda </option> 
            <option value="ONGs"> ONGs e Instituições Filantrópicas</option>  
            <option value="Saúde"> Saúde </option> 
            <option value="Segurança"> Segurança </option> 
            <option value="Tecnologia"> Tecnologia </option> 
            <option value="Transportes"> Transportes </option> 
            <option value="Turismo"> Turismo/Viagens </option> 
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="publicoAlvo">{t('Qual o público-alvo?')}</label>
          <select
            id="publicoAlvo"
            value={publicoAlvo}
            onChange={(e) => setPublicoAlvo(e.target.value)}
            required
          >
            <option value="">{t('Selecione o público-alvo')}</option>
            <option value="Artistas"> Artistas </option> 
            <option value="Atletas"> Atletas </option> 
            <option value="Clientes"> Clientes Finais</option> 
            <option value="Consumidores"> Consumidores de E-commerce</option>  
            <option value="Corretoras"> Corretoras </option> 
            <option value="Designer"> Designer/Profissionais Criativos</option> 
            <option value="Empresas"> Empresas B2B</option>  
            <option value="Empresas"> Empresas de Logística</option>  
            <option value="Empresas"> Empresas de Segurança</option>  
            <option value="Entregadores"> Entregadores/Motoristas</option> 
            <option value="Estudantes"> Estudantes </option> 
            <option value="Funcionários"> Funcionários/Colaboradores </option> 
            <option value="Gerentes"> Gerentes/Administradores </option> 
            <option value="Imobiliárias"> Imobiliárias </option> 
            <option value="Instituições"> Instituições Financeiras</option>  
            <option value="Investidores"> Investidores </option> 
            <option value="Jornalistas"> Jornalistas </option> 
            <option value="Médicos"> Médicos/Profissionais de Saúde </option> 
            <option value="ONGs"> ONGs e Voluntários</option>  
            <option value="Pacientes"> Pacientes </option> 
            <option value="Pais"> Pais e Responsáveis</option> 
            <option value="Proprietários"> Proprietários de Imóveis</option>  
            <option value="Técnicos"> Técnicos de Manutenção</option>  
            <option value="Turistas"> Turistas </option> 
            <option value="Usuários"> Usuários de Redes Sociais</option>  
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="funcionalidade">
            {t('Qual a principal funcionalidade do projeto?')}
          </label>
          <select
            id="funcionalidade"
            value={funcionalidade}
            onChange={(e) => setFuncionalidade(e.target.value)}
            required
          >
            <option value="">{t('Selecione a funcionalidade')}</option>
            <option value="Agendamento_Serviços"> Agendamento de Serviços</option> 
            <option value="App_Delivery"> App de Delivery</option> 
            <option value="Biblioteca_Digital"> Biblioteca Digital</option> 
            <option value="Blog/Portal_Notícias"> Blog/Portal de Notícias </option>
            <option value="Controle_Gastos/Orçamento"> Controle de Gastos/Orçamento </option> 
            <option value="Dashboard_Indicadores"> Dashboard de Indicadores</option> 
            <option value="E-commerce"> E-commerce (Vendas Online) </option>
            <option value="Ferramenta_Colaboração"> Ferramenta de Colaboração (Ex.: Slack, Trello)</option> 
            <option value="Gerenciamento_Condomínios"> Gerenciamento de Condomínios</option> 
            <option value="Gerenciamento_Documentos"> Gerenciamento  de Documentos</option>
            <option value="Gerenciamento_Estoque"> Gerenciamento de Estoque</option> 
            <option value="Gerenciamento_Imóveis"> Gerenciamento de Imóveis</option> 
            <option value="Gerenciamento_Prontuários"> Gerenciamento de Prontuários</option> 
            <option value="Gerenciamento_Projetos"> Gerenciamento de Projetos </option> 
            <option value="Gestão_Recursos_Humanos"> Gestão de Recursos Humanos</option> 
            <option value="Integração_Redes_Sociais"> Integração com Redes Sociais</option> 
            <option value="Mapeamento_Rastreamento_Entregas"> Mapeamento e Rastreamento de Entregas</option> 
            <option value="Marketplace"> Marketplace </option>
            <option value="Monitoramento de Saúde"> Monitoramento de Saúde</option> 
            <option value="Plataforma_Automação Residencial"> Plataforma de Automação Residencial</option> 
            <option value="Plataforma_Ensino à Distância (EAD)"> Plataforma de Ensino à Distância (EAD)</option> 
            <option value="Plataforma_Ensino/Quiz"> Plataforma de Ensino/Quiz</option> 
            <option value="Plataforma_Streaming"> Plataforma e Streaming</option> d
            <option value="Relatórios_Financeiros"> Relatórios Financeiros</option> 
            <option value="Reserva_Serviços/Recursos"> Reserva de Serviços/Recursos</option> 
            <option value="Simulação_Crédito"> Simulação de Crédito</option> 
            <option value="Sistema_Auditoria_Compliance"> Sistema de Auditoria e Compliance</option> 
            <option value="Sistema_CRM"> Sistema de CRM</option> 
            <option value="Sistema_Pagamento"> Sistema de Pagamento</option> 
            <option value="Suporte_Cliente"> Suporte ao Cliente (Chat/FAQ)</option> 
            <option value="Telemedicina"> Telemedicina </option>
          </select>
        </FormGroup>

        <FormGroup>
        <label htmlFor="integracao">{t('Há necessidade de integração com outros sistemas?')}</label>
        <select
          id="integracao"
          value={integracao}
          onChange={handleIntegracaoChange}
          required
        >
          <option value="">{t('Selecione')}</option>
          <option value="Sim">{t('Sim')}</option>
          <option value="Não">{t('Não')}</option>
        </select>
      </FormGroup>

      {integracao === 'Sim' && (
        <FormGroup>
          <label htmlFor="tipoIntegracao">{t('Selecione o tipo de integração')}</label>
          <select
            id="tipoIntegracao"
            value={tipoIntegracao}
            onChange={(e) => setTipoIntegracao(e.target.value)}
            required
          >
            <option value="">{t('Selecione')}</option>
            <option value="RP">{t('RP (Enterprise Resource Planning) (Exemplo: SAP, Oracle)')}</option>
            <option value="CRM">{t('CRM (Customer Relationship Management) (Exemplo: Salesforce, HubSpot)')}</option>
            <option value="Pagamentos">{t('Sistemas de Pagamento Online (Exemplo: PayPal, Stripe, PagSeguro)')}</option>
            <option value="API">{t('API de Bancos ou Instituições Financeiras (Exemplo: Banco Central, Open Banking)')}</option>
            <option value="Plataformas Ensino">{t('Plataformas de Ensino (Exemplo: Moodle, Blackboard)')}</option>
            <option value="Telemedicina">{t('Plataformas de Telemedicina (Exemplo: Doctoralia, Medtronic)')}</option>
            <option value="Sistemas Hospitalares">{t('Sistemas de Gestão Hospitalar (Exemplo: Tasy, MV)')}</option>
            <option value="E-commerce">{t('Sistemas de E-commerce (Exemplo: Magento, Shopify, WooCommerce)')}</option>
            <option value="Marketing">{t('Ferramentas de Automação de Marketing (Exemplo: MailChimp, RD Station)')}</option>
            <option value="Nuvem">{t('Serviços de Armazenamento em Nuvem (Exemplo: Google Cloud, AWS, Microsoft Azure)')}</option>
            <option value="Industrial">{t('Sistemas de Automação Industrial (Exemplo: SCADA, MES)')}</option>
            <option value="Analise Dados">{t('Ferramentas de Análise de Dados (Exemplo: Power BI, Google Analytics)')}</option>
            <option value="Controle Acesso">{t('Sistemas de Controle de Acesso (Exemplo: Face ID, RFID, Senhas)')}</option>
            <option value="Redes Sociais">{t('Plataformas de Redes Sociais (Exemplo: Facebook, Instagram, LinkedIn)')}</option>
            <option value="IoT">{t('Integração com Sensores ou Dispositivos IoT (Exemplo: Arduino, Raspberry Pi, SmartThings)')}</option>
            <option value="Frotas">{t('Sistemas de Gestão de Frotas (Exemplo: Samsara, Fleet Complete)')}</option>
            <option value="Automacao Residencial">{t('Ferramentas de Automação Residencial (Exemplo: Alexa, Google Home, Philips Hue)')}</option>
            <option value="Seguranca">{t('Sistemas de Monitoramento de Segurança (Exemplo: Câmeras IP, Alarmes)')}</option>
            <option value="Imoveis">{t('Sistemas de Gestão de Imóveis (Exemplo: Real Estate CRM, Locações)')}</option>
            <option value="Outros">{t('Outros')}</option>
          </select>
        </FormGroup>
      )}

      {tipoIntegracao === 'Outros' && (
        <FormGroup>
          <label htmlFor="outrosIntegracao">{t('Descreva a integração desejada')}</label>
          <input
            type="text"
            id="outrosIntegracao"
            value={outrosIntegracao}
            onChange={(e) => setOutrosIntegracao(e.target.value)}
            required
          />
        </FormGroup>
      )}

        <FormGroup>
          <label htmlFor="usuariosMensais">{t('Quantos usuários esperados mensalmente?')}</label>
          <input
            type="number"
            id="usuariosMensais"
            value={usuariosMensais}
            onChange={(e) => setUsuariosMensais(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="restricaoTecnologica">
            {t('Há alguma restrição ou tecnologia específica que deve ser usada?')}
          </label>
          <input
            type="text"
            id="restricaoTecnologica"
            value={restricaoTecnologica}
            onChange={(e) => setRestricaoTecnologica(e.target.value)}
          />
        </FormGroup>

        <FormGroup>
          <label htmlFor="apiExterna">
            {t('Há necessidade de desenvolvimento de API Externa?')}
          </label>
          <select
            id="apiExterna"
            value={apiExterna}
            onChange={(e) => setApiExterna(e.target.value)}
            required
          >
            <option value="">{t('Selecione')}</option>
            <option value="Sim">{t('Sim')}</option>
            <option value="Não">{t('Não')}</option>
          </select>
        </FormGroup>

        <FormGroup>
          <label htmlFor="designNecessario">
            {t('Há necessidade de criação de design?')}
          </label>
          <select
            id="designNecessario"
            value={designNecessario}
            onChange={(e) => setDesignNecessario(e.target.value)}
            required
          >
            <option value="">{t('Selecione')}</option>
            <option value="Sim">{t('Sim')}</option>
            <option value="Não">{t('Não')}</option>
          </select>
        </FormGroup>
    {/* Campo para urgência */}
    <FormGroup>
            <label>{t('Qual a urgência do projeto?')}</label>
            <select
                value={urgencia}
                onChange={(e) => setUrgencia(e.target.value)}
                required
            >
                <option value="">{t('Selecione')}</option>
                <option value="Normal">{t('Normal')}</option>
                <option value="Urgente">{t('Urgente')}</option>
            </select>
            </FormGroup>
            <SubmitButton type="submit">{t('Fazer Orçamento Detalhado')}</SubmitButton>
        </Form>
        </SimulacaoContainer>
    );
};

// Estilos com styled-components
const SimulacaoContainer = styled.div`
  padding: 60px 20px;
  background-color: #f9f9f9;
`;

const Form = styled.form`
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  input, select, textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
`;

const TotalContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const Valor = styled.p`
  font-size: 2rem;
  font-weight: bold;
  color: #000;
`;

const SubmitButton = styled.button`
  background-color: #000;
  color: white;
  padding: 15px;
  border-radius: 5px;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  &:hover {
    background-color: #444;
  }
`;

export default SimulacaoOrcamento;